import React from "react"
import * as amplitude from '@amplitude/analytics-browser';
import _ from 'lodash'
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom"
import { useReactiveVar } from "@apollo/client"
import { userVar, errorsVar, successVar } from './cache/cache'
import { useMeQuery } from './types/graphql'
import usePrevious from './hooks/usePrevious'
import { Instructions } from "./pages/user/Instructions"
//Text pages
const Typography = React.lazy(() => import("./pages/common/Typography"))
const Policy = React.lazy(() => import("./pages/common/Policy"))
const Cookies = React.lazy(() => import("./pages/common/Cookies"))
// const Intellectual = React.lazy(() => import("./pages/common/Intellectual"))
// const AccountDetails = React.lazy(() => import("./pages/common/AccountDetails"))
const Terms = React.lazy(() => import("./pages/common/Terms"))
const FAQ = React.lazy(() => import("./pages/user/FAQ"))
const Program = React.lazy(() => import("./pages/user/Program"))
const Material = React.lazy(() => import("./pages/user/Material"))

//Interactive pages
const Main = React.lazy(() => import("./pages/common/Main"))
const Auth = React.lazy(() => import("./pages/common/Auth"))
const Register = React.lazy(() => import("./pages/common/Register"))
const Restore = React.lazy(() => import("./pages/common/Restore"))
const Settings = React.lazy(() => import("./pages/common/Settings"))
const Lections = React.lazy(() => import("./pages/user/Lections"))
const Lection = React.lazy(() => import("./pages/user/Lection"))
const Diploms = React.lazy(() => import("./pages/user/Diploms"))
const Progress = React.lazy(() => import("./pages/user/Progress"))
const Dialogs = React.lazy(() => import("./pages/common/Dialogs"))
const Dialog = React.lazy(() => import("./pages/common/Dialog"))
const CreateDialog = React.lazy(() => import("./pages/common/CreateDialog"))
const NotFound = React.lazy(() => import("./pages/common/NotFound"))

const Trainings = React.lazy(() => import("./pages/admin/Trainings"))
const CreateTraining = React.lazy(() => import("./pages/admin/CreateTraining"))
const EditTraining = React.lazy(() => import("./pages/admin/EditTraining"))
const Groups = React.lazy(() => import("./pages/admin/Groups"))
const CreateGroup = React.lazy(() => import("./pages/admin/CreateGroup"))
const GroupLections = React.lazy(() => import("./pages/admin/GroupLections"))
const EditGroup = React.lazy(() => import("./pages/admin/EditGroup"))
const Users = React.lazy(() => import("./pages/admin/Users"))
const UserGroups = React.lazy(() => import("./pages/admin/UserGroups"))
const UserLections = React.lazy(() => import("./pages/admin/UserLections"))
const CreateUser = React.lazy(() => import("./pages/admin/CreateUser"))
const EditUser = React.lazy(() => import("./pages/admin/EditUser"))
const Pages = React.lazy(() => import("./pages/admin/Pages"))
const Statistics = React.lazy(() => import("./pages/admin/Statistics"))

const App: React.FC = () => {

	let user = useReactiveVar(userVar)
	const { loading } = useMeQuery({
		fetchPolicy: "network-only",
		notifyOnNetworkStatusChange: true,
		onCompleted: data => {
			userVar(data?.me)

			const selected_group_id = localStorage.getItem("selectedGroupId")
			const groupId = (data?.me && data?.me?.trainings && data.me.trainings.length > 0) ? (data?.me?.trainings[0]?.id || "") : ""

			if (data?.me) {
				amplitude.init('f64fda8e3e9b3548ed97366f405105f4', data?.me?.id || "",
				{
					// "autocapture": true ,
					defaultTracking: true,
				}
				);
				amplitude.setUserId(data?.me?.id || "");
				const identify = new amplitude.Identify()
				.set('email', data?.me?.email || "")
				.set('groupId', selected_group_id || groupId || '')
				amplitude.identify(identify);
			}
			
			if (data?.me && data?.me?.trainings && data.me.trainings.length > 0) {
				if (selected_group_id !== null) {
					const hasGroup = _.some(data.me.trainings, { id: selected_group_id })
					if (!hasGroup) {
						localStorage.removeItem("selectedGroupId")
					}
				} else {
					localStorage.setItem("selectedGroupId", groupId)
				}
			} else {
				localStorage.removeItem("selectedGroupId")
			}

		},
		onError: err => {
			console.log('OnError', err)
		},
		errorPolicy: 'ignore'
  	})

  	if (loading && user === undefined) return <div className="preloader"></div>

	return (
		<div className="App">
			<BrowserRouter>
				<React.Suspense fallback={<div className="preloader"></div>}>
				<Routes>
					<Route path={`/`} element={<Main />} />
					<Route path={`/auth`} element={<Auth />} />
					<Route path={`/register`} element={<Register />} />
					<Route path={`/restore`} element={<Restore />} />
					<Route path={`/lections`} element={<Lections />} />
					<Route path={`/lections/:lectionId`} element={<Lection />} />
					<Route path={`/settings`} element={<Settings />} />
					<Route path={`/diploms`} element={<Diploms />} />
					<Route path={`/progress`} element={<Progress />} />
					<Route path={`/create-dialog`} element={<CreateDialog />} />
					<Route path={`/dialogs`} element={<Dialogs />} />
					<Route path={`/dialogs/:dialogId`} element={<Dialog />} />

					<Route path={`/trainings`} element={<Trainings />} />
					<Route path={`/trainings/create`} element={<CreateTraining />} />
					<Route path={`/trainings/:trainingId/edit`} element={<EditTraining />} />
					<Route path={`/groups`} element={<Groups />} />
					<Route path={`/groups/create`} element={<CreateGroup />} />
					<Route path={`/groups/:groupId/lections`} element={<GroupLections />} />
					<Route path={`/groups/:groupId/edit`} element={<EditGroup />} />
					<Route path={`/users`} element={<Users />} />
					<Route path={`/users/create`} element={<CreateUser />} /> 
					<Route path={`/users/:userId/groups`} element={<UserGroups />} />
					<Route path={`/users/:userId/lections`} element={<UserLections />} />
					<Route path={`/users/:userId/edit`} element={<EditUser />} /> 
					<Route path={`/pages`} element={<Pages />} />
					<Route path={`/statistics`} element={<Statistics />} />

					<Route path={`/policy`} element={<Policy />} />
					<Route path={`/cookies`} element={<Cookies />} />
					{/* <Route path={`/intellectual`} element={<Intellectual />} /> */}
					{/* <Route path={`/account-details`} element={<AccountDetails />} /> */}
					<Route path={`/instructions`} element={<Instructions />} />
					<Route path={`/terms`} element={<Terms />} />
					<Route path={`/faq`} element={<FAQ />} />
					<Route path={`/program`} element={<Program />} />
					<Route path={`/material`} element={<Material />} />
					<Route path={`/typography`} element={<Typography />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
				</React.Suspense>
				<Location />
			</BrowserRouter>
		</div>
	)
}

const Location: React.FC = () => {
    const location = useLocation()
    const errors = useReactiveVar(errorsVar)
    const success = useReactiveVar(successVar)
    const prevPageKey = usePrevious(location.key)

    //Delete errors and successes
    React.useEffect(() => {
        if (location.key !== prevPageKey && prevPageKey !== undefined) {
            if (errors.length > 0) errorsVar([])
            if (success !== null) successVar(null)
        }
    }, [prevPageKey, location, errors, success])

    return <></>
}

export default App
